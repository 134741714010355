/**
 * Calculate Posts
 *
 * @param {number} length The length in inches of the pergola
 * @param {number} width The width in inches of the pergola
 * @return {number}
 */
export const calculatePosts = (length: number, width: number): number => {
  const shortSide = Math.min(length, width);
  const longSide = Math.max(length, width);

  if (shortSide < 240 && longSide < 240) {
    return 4;
  }

  if (shortSide < 240 && longSide >= 240 && longSide < 480) {
    return 6;
  }

  if (shortSide < 240 && longSide >= 480 && longSide < 720) {
    return 8;
  }

  if (shortSide >= 240 && shortSide < 480 && longSide >= 240 && longSide < 480) {
    return 9;
  }

  return 12;
};

//=if(and(D4<=240,D6<=240),4,
// if(and(and(D4>240,D4<=480),D6<=240),6,
// if(and(and(D4>480,D4<=720),D6<=240),8,
// if(and(and(D4>240,D4<=480),and(D6>240,D6<=480)),9,
// if(and(and(D4>480,D4<=720),and(D6>240,D6<=480)),12,"ERROR")))))
