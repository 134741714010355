import { gql } from '@apollo/client';
import client from '../apollo-client';

export const createCheckout = async (variantId: string) => {
  console.log('variantId: ', variantId);

  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation CreateCheckout($input: CartInput!){
        cartCreate(input: $input) {
          cart {
            id
            checkoutUrl
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `, variables: {
      input: {
        lines: [{ merchandiseId: variantId, quantity: 1 }],
        buyerIdentity: {
          countryCode: 'US'
        }
      }
    }
  });

  // const products = data.products;
  console.error('Error: ', errors);

  return {
    checkoutURL: data.cartCreate.cart.checkoutUrl,
  };
};
