import React, { FC } from 'react';
import { lengthPositionMetersNegative, lengthPositionMetersPositive } from '../../utilities/positioning';
import { HardwareMaterial } from '../../materials/HardwareMaterial';
import { StructureMaterial } from '../../materials/StructureMaterial';
import { PergolaDesign, usePergola } from '../../../data-stores/pergola';

interface LegsProps {
  lPositionMP: (x: number) => number;
  wPositionMP: (x: number) => number;
  hScale: (x: number) => number;
  wPositionMN: (x: number) => number;
  hPositionCm: (x: number) => number;
  lPositionMN: (x: number) => number;
  totalWidthInches: number;
  totalLengthInches: number;
  nodes: any; // TODO add type
}

export const Legs: FC<LegsProps> = ({
  lPositionMP,
  wPositionMP,
  hScale,
  wPositionMN,
  hPositionCm,
  lPositionMN,
  totalWidthInches,
  totalLengthInches,
  nodes
}): JSX.Element => {
  const pergolaType = usePergola((s) => s.design.pergola);

  const lMidP = lengthPositionMetersPositive(totalLengthInches / 3);
  const lMidN = lengthPositionMetersNegative(totalLengthInches / 3);

  return (
    <>
      {pergolaType === PergolaDesign.FREE_STANDING && (
        <group position={[lPositionMP(-1.73), 0, wPositionMP(-1.52)]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Leg_back_left_2.geometry}
            material={nodes.Leg_back_left_2.material}
            scale={[1, 1, hScale(1)]}
          >
            <StructureMaterial vertical={true} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Leg_back_left_lower_element.geometry}
            material={nodes.Leg_back_left_lower_element.material}
            position={[0.02, 0.06, -2.95]}
          >
            <HardwareMaterial/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Leg_back_left_screws_bottom.geometry}
            material={nodes.Leg_back_left_screws_bottom.material}
            position={[0.02, -3.67, -3.35]}
            rotation={[0, -0.16, Math.PI / 2]}
          >
            <HardwareMaterial/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Leg_back_left_screws_top.geometry}
            material={nodes.Leg_back_left_screws_top.material}
            position={[0.02, 0.05, hPositionCm(-226.2)]}
            rotation={[-Math.PI / 2, 1.13, 0]}
          >
            <HardwareMaterial/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Leg_back_left_upper_element.geometry}
            material={nodes.Leg_back_left_upper_element.material}
            position={[0.02, 0.06, hPositionCm(-239.55)]}
          >
            <HardwareMaterial/>
          </mesh>
        </group>
      )}

      <group position={[lPositionMP(-1.73), 0, wPositionMN(1.52)]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Leg_back_right_2.geometry}
          material={nodes.Leg_back_right_2.material}
          scale={[1, 1, hScale(1)]}


        >

          <StructureMaterial vertical={true} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Leg_back_right_lower_element.geometry}
          material={nodes.Leg_back_right_lower_element.material}
          position={[0.02, 0.01, -2.95]}
        >
          <HardwareMaterial/>
        </mesh>
        <mesh
          geometry={nodes.Leg_back_right_screws_bottom.geometry}
          material={nodes.Leg_back_right_screws_bottom.material}
          position={[0, 0.6, -6.35]}
          rotation={[0, 1.37, Math.PI / 2]}
        >
          <HardwareMaterial/>
        </mesh>
        <mesh
          geometry={nodes.Leg_back_right_screws_top.geometry}
          material={nodes.Leg_back_right_screws_top.material}
          position={[0.02, 0.01, hPositionCm(-226.21)]}
          rotation={[0, 1.57, 0]}
        >
          <HardwareMaterial/>
        </mesh>
        <mesh
          geometry={nodes.Leg_back_right_upper_element.geometry}
          material={nodes.Leg_back_right_upper_element.material}
          position={[0.02, 0.01, hPositionCm(-239.55)]}
        >
          <HardwareMaterial/>
        </mesh>
      </group>

      {totalLengthInches >= 240 && totalLengthInches < 480 && (
        <>
          <group position={[0, 0, wPositionMN(1.52)]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_back_right_2.geometry}
              material={nodes.Leg_back_right_2.material}
              scale={[1, 1, hScale(1)]}
            >
             <StructureMaterial vertical={true} />
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_back_right_lower_element.geometry}
              material={nodes.Leg_back_right_lower_element.material}
              position={[0.02, 0.01, -2.95]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              geometry={nodes.Leg_back_right_screws_bottom.geometry}
              material={nodes.Leg_back_right_screws_bottom.material}
              position={[0, 0.6, -6.35]}
              rotation={[0, 1.37, Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              geometry={nodes.Leg_back_right_screws_top.geometry}
              material={nodes.Leg_back_right_screws_top.material}
              position={[0.02, 0.01, hPositionCm(-226.21)]}
              rotation={[0, 1.57, 0]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              geometry={nodes.Leg_back_right_upper_element.geometry}
              material={nodes.Leg_back_right_upper_element.material}
              position={[0.02, 0.01, hPositionCm(-239.55)]}
            >
              <HardwareMaterial/>
            </mesh>
          </group>
          {pergolaType === PergolaDesign.FREE_STANDING && (
            <group position={[0, 0, wPositionMP(-1.52)]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Leg_back_left_2.geometry}
                material={nodes.Leg_back_left_2.material}
                scale={[1, 1, hScale(1)]}
              >
                <StructureMaterial vertical={true} />
              </mesh>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Leg_back_left_lower_element.geometry}
                material={nodes.Leg_back_left_lower_element.material}
                position={[0.02, 0.06, -2.95]}
              >
                <HardwareMaterial/>
              </mesh>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Leg_back_left_screws_bottom.geometry}
                material={nodes.Leg_back_left_screws_bottom.material}
                position={[0.02, -3.67, -3.35]}
                rotation={[0, -0.16, Math.PI / 2]}
              >
                <HardwareMaterial/>
              </mesh>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Leg_back_left_screws_top.geometry}
                material={nodes.Leg_back_left_screws_top.material}
                position={[0.02, 0.05, hPositionCm(-226.2)]}
                rotation={[-Math.PI / 2, 1.13, 0]}
              >
                <HardwareMaterial/>
              </mesh>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Leg_back_left_upper_element.geometry}
                material={nodes.Leg_back_left_upper_element.material}
                position={[0.02, 0.06, hPositionCm(-239.55)]}
              >
                <HardwareMaterial/>
              </mesh>
            </group>
          )}
        </>
      )}

      {totalLengthInches >= 480 && (
        <>
          <group position={[lMidP(-1.73) + 0.045, 0, wPositionMN(1.52)]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_back_right_2.geometry}
              material={nodes.Leg_back_right_2.material}
              scale={[1, 1, hScale(1)]}
            >
             <StructureMaterial vertical={true} />
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_back_right_lower_element.geometry}
              material={nodes.Leg_back_right_lower_element.material}
              position={[0.02, 0.01, -2.95]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              geometry={nodes.Leg_back_right_screws_bottom.geometry}
              material={nodes.Leg_back_right_screws_bottom.material}
              position={[0, 0.6, -6.35]}
              rotation={[0, 1.37, Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              geometry={nodes.Leg_back_right_screws_top.geometry}
              material={nodes.Leg_back_right_screws_top.material}
              position={[0.02, 0.01, hPositionCm(-226.21)]}
              rotation={[0, 1.57, 0]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              geometry={nodes.Leg_back_right_upper_element.geometry}
              material={nodes.Leg_back_right_upper_element.material}
              position={[0.02, 0.01, hPositionCm(-239.55)]}
            >
              <HardwareMaterial/>
            </mesh>
          </group>
          {pergolaType === PergolaDesign.FREE_STANDING && (
            <>
              <group position={[lMidP(-1.73) + 0.045, 0, wPositionMP(-1.52)]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Leg_back_left_2.geometry}
                  material={nodes.Leg_back_left_2.material}
                  scale={[1, 1, hScale(1)]}
                >
                  <StructureMaterial vertical={true} />
                </mesh>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Leg_back_left_lower_element.geometry}
                  material={nodes.Leg_back_left_lower_element.material}
                  position={[0.02, 0.06, -2.95]}
                >
                  <HardwareMaterial/>
                </mesh>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Leg_back_left_screws_bottom.geometry}
                  material={nodes.Leg_back_left_screws_bottom.material}
                  position={[0.02, -3.67, -3.35]}
                  rotation={[0, -0.16, Math.PI / 2]}
                >
                  <HardwareMaterial/>
                </mesh>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Leg_back_left_screws_top.geometry}
                  material={nodes.Leg_back_left_screws_top.material}
                  position={[0.02, 0.05, hPositionCm(-226.2)]}
                  rotation={[-Math.PI / 2, 1.13, 0]}
                >
                  <HardwareMaterial/>
                </mesh>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Leg_back_left_upper_element.geometry}
                  material={nodes.Leg_back_left_upper_element.material}
                  position={[0.02, 0.06, hPositionCm(-239.55)]}
                >
                  <HardwareMaterial/>
                </mesh>
              </group>
              <group position={[lMidN(1.72) - 0.045, 0, wPositionMP(-1.52)]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Leg_front_left_2.geometry}
                  material={nodes.Leg_front_left_2.material}
                  scale={[1, 1, hScale(1)]}
                >
                  <StructureMaterial vertical={true} />
                </mesh>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Leg_front_left_lower_element.geometry}
                  material={nodes.Leg_front_left_lower_element.material}
                  position={[0, 0.06, -2.95]}
                >
                  <HardwareMaterial/>
                </mesh>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Leg_front_left_screws_bottom.geometry}
                  material={nodes.Leg_front_left_screws_bottom.material}
                  position={[0, 0.6, -6.35]}
                  rotation={[0, 1.37, Math.PI / 2]}
                >
                  <HardwareMaterial/>
                </mesh>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Leg_front_left_screws_top.geometry}
                  material={nodes.Leg_front_left_screws_top.material}
                  position={[0, 0.06, hPositionCm(-226.2)]}
                  rotation={[0, 0, -Math.PI / 2]}
                >
                  <HardwareMaterial/>
                </mesh>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Leg_front_left_upper_element.geometry}
                  material={nodes.Leg_front_left_upper_element.material}
                  position={[0, 0.06, hPositionCm(-239.55)]}
                >
                  <HardwareMaterial/>
                </mesh>
              </group>
            </>
          )}
          <group position={[lMidN(1.72) - 0.045, 0, wPositionMN(1.52)]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_right_2.geometry}
              material={nodes.Leg_front_right_2.material}
              scale={[1, 1, hScale(1)]}
            >
             <StructureMaterial vertical={true} />
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_right_lower_element.geometry}
              material={nodes.Leg_front_right_lower_element.material}
              position={[0, 0.06, -2.95]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_right_screws_bottom.geometry}
              material={nodes.Leg_front_right_screws_bottom.material}
              position={[0, 0.6, -6.35]}
              rotation={[0, 1.37, Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_right_screws_top.geometry}
              material={nodes.Leg_front_right_screws_top.material}
              position={[0, 0.06, hPositionCm(-226.2)]}
              rotation={[0, 0, -Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_right_upper_element.geometry}
              material={nodes.Leg_front_right_upper_element.material}
              position={[0, 0.06, hPositionCm(-239.55)]}
            >
              <HardwareMaterial/>
            </mesh>
          </group>
        </>
      )}

      {totalWidthInches >= 240 && (
        <>
          <group position={[lPositionMN(1.72), 0, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_2.geometry}
              material={nodes.Leg_front_left_2.material}
              scale={[1, 1, hScale(1)]}
            >
             <StructureMaterial vertical={true} />
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_lower_element.geometry}
              material={nodes.Leg_front_left_lower_element.material}
              position={[0, 0.06, -2.95]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_screws_bottom.geometry}
              material={nodes.Leg_front_left_screws_bottom.material}
              position={[0, 0.6, -6.35]}
              rotation={[0, 1.37, Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_screws_top.geometry}
              material={nodes.Leg_front_left_screws_top.material}
              position={[0, 0.06, hPositionCm(-226.2)]}
              rotation={[0, 0, -Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_upper_element.geometry}
              material={nodes.Leg_front_left_upper_element.material}
              position={[0, 0.06, hPositionCm(-239.55)]}
            >
              <HardwareMaterial/>
            </mesh>
          </group>
          <group position={[lPositionMP(-1.72), 0, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_right_2.geometry}
              material={nodes.Leg_front_right_2.material}
              scale={[1, 1, hScale(1)]}
            >
             <StructureMaterial vertical={true} />
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_right_lower_element.geometry}
              material={nodes.Leg_front_right_lower_element.material}
              position={[0, 0.06, -2.95]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_right_screws_bottom.geometry}
              material={nodes.Leg_front_right_screws_bottom.material}
              position={[0, 0.6, -6.35]}
              rotation={[0, 1.37, Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_right_screws_top.geometry}
              material={nodes.Leg_front_right_screws_top.material}
              position={[0, 0.06, hPositionCm(-226.2)]}
              rotation={[0, 0, -Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_right_upper_element.geometry}
              material={nodes.Leg_front_right_upper_element.material}
              position={[0, 0.06, hPositionCm(-239.55)]}
            >
              <HardwareMaterial/>
            </mesh>
          </group>
        </>
      )}

      {/*// Middle */}
      {(totalLengthInches >= 240 && totalLengthInches < 480) && totalWidthInches >= 240 && (
        <>
          <group position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_2.geometry}
              material={nodes.Leg_front_left_2.material}
              scale={[1, 1, hScale(1)]}
            >
             <StructureMaterial vertical={true} />
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_lower_element.geometry}
              material={nodes.Leg_front_left_lower_element.material}
              position={[0, 0.06, -2.95]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_screws_bottom.geometry}
              material={nodes.Leg_front_left_screws_bottom.material}
              position={[0, 0.6, -6.35]}
              rotation={[0, 1.37, Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_screws_top.geometry}
              material={nodes.Leg_front_left_screws_top.material}
              position={[0, 0.06, hPositionCm(-226.2)]}
              rotation={[0, 0, -Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_upper_element.geometry}
              material={nodes.Leg_front_left_upper_element.material}
              position={[0, 0.06, hPositionCm(-239.55)]}
            >
              <HardwareMaterial/>
            </mesh>
          </group>
        </>
      )}

      {totalLengthInches >= 480 && totalWidthInches >= 240 && (
        <>
          <group position={[lMidN(1.72) - 0.045, 0, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_2.geometry}
              material={nodes.Leg_front_left_2.material}
              scale={[1, 1, hScale(1)]}
            >
             <StructureMaterial vertical={true} />
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_lower_element.geometry}
              material={nodes.Leg_front_left_lower_element.material}
              position={[0, 0.06, -2.95]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_screws_bottom.geometry}
              material={nodes.Leg_front_left_screws_bottom.material}
              position={[0, 0.6, -6.35]}
              rotation={[0, 1.37, Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_screws_top.geometry}
              material={nodes.Leg_front_left_screws_top.material}
              position={[0, 0.06, hPositionCm(-226.2)]}
              rotation={[0, 0, -Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_upper_element.geometry}
              material={nodes.Leg_front_left_upper_element.material}
              position={[0, 0.06, hPositionCm(-239.55)]}
            >
              <HardwareMaterial/>
            </mesh>
          </group>
          <group position={[lMidP(-1.72) + 0.045, 0, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_2.geometry}
              material={nodes.Leg_front_left_2.material}
              scale={[1, 1, hScale(1)]}
            >
             <StructureMaterial vertical={true} />
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_lower_element.geometry}
              material={nodes.Leg_front_left_lower_element.material}
              position={[0, 0.06, -2.95]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_screws_bottom.geometry}
              material={nodes.Leg_front_left_screws_bottom.material}
              position={[0, 0.6, -6.35]}
              rotation={[0, 1.37, Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_screws_top.geometry}
              material={nodes.Leg_front_left_screws_top.material}
              position={[0, 0.06, hPositionCm(-226.2)]}
              rotation={[0, 0, -Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Leg_front_left_upper_element.geometry}
              material={nodes.Leg_front_left_upper_element.material}
              position={[0, 0.06, hPositionCm(-239.55)]}
            >
              <HardwareMaterial/>
            </mesh>
          </group>
        </>
      )}
      {/*// Middle END */}

      {pergolaType === PergolaDesign.FREE_STANDING && (
        <group position={[lPositionMN(1.72), 0, wPositionMP(-1.52)]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Leg_front_left_2.geometry}
            material={nodes.Leg_front_left_2.material}
            scale={[1, 1, hScale(1)]}
          >
            <StructureMaterial vertical={true} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Leg_front_left_lower_element.geometry}
            material={nodes.Leg_front_left_lower_element.material}
            position={[0, 0.06, -2.95]}
          >
            <HardwareMaterial/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Leg_front_left_screws_bottom.geometry}
            material={nodes.Leg_front_left_screws_bottom.material}
            position={[0, 0.6, -6.35]}
            rotation={[0, 1.37, Math.PI / 2]}
          >
            <HardwareMaterial/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Leg_front_left_screws_top.geometry}
            material={nodes.Leg_front_left_screws_top.material}
            position={[0, 0.06, hPositionCm(-226.2)]}
            rotation={[0, 0, -Math.PI / 2]}
          >
            <HardwareMaterial/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Leg_front_left_upper_element.geometry}
            material={nodes.Leg_front_left_upper_element.material}
            position={[0, 0.06, hPositionCm(-239.55)]}
          >
            <HardwareMaterial/>
          </mesh>
        </group>
      )}
      <group position={[lPositionMN(1.72), 0, wPositionMN(1.52)]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Leg_front_right_2.geometry}
          material={nodes.Leg_front_right_2.material}
          scale={[1, 1, hScale(1)]}
        >
          <StructureMaterial vertical={true} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Leg_front_right_lower_element.geometry}
          material={nodes.Leg_front_right_lower_element.material}
          position={[0, 0.06, -2.95]}
        >
          <HardwareMaterial/>
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Leg_front_right_screws_bottom.geometry}
          material={nodes.Leg_front_right_screws_bottom.material}
          position={[0, 0.6, -6.35]}
          rotation={[0, 1.37, Math.PI / 2]}
        >
          <HardwareMaterial/>
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Leg_front_right_screws_top.geometry}
          material={nodes.Leg_front_right_screws_top.material}
          position={[0, 0.06, hPositionCm(-226.2)]}
          rotation={[0, 0, -Math.PI / 2]}
        >
          <HardwareMaterial/>
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Leg_front_right_upper_element.geometry}
          material={nodes.Leg_front_right_upper_element.material}
          position={[0, 0.06, hPositionCm(-239.55)]}
        >
          <HardwareMaterial/>
        </mesh>
      </group>
    </>
  );
};
