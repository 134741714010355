import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const shop_id = 'paragon-outdoor-usa'
const httpLink = createHttpLink({
  uri: `https://${shop_id}.myshopify.com/api/2025-04/graphql.json`
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-Shopify-Storefront-Access-Token': 'a194cccd327c17808145e346df1ced4d',
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  }
});


export default client;
